if (typeof TGN == 'undefined') {
    var TGN = {};
    TGN.SRHover = {};
}
else if (typeof TGN.SRHover == 'undefined')
    TGN.SRHover = {};

//Hover Card Variables
TGN.HoverCard = {
    Event: "",
    TheDB: "",
    TheRec: "",
    HeaderTxt: "",
    SubDB: "",
    Iid: "",
    Rc: "",
    xtraQSParam: "",
    NoHover: "",
    ElId: "",
    DestUrl: ""
};

TGN.SRHover.Xoverride = 0;
TGN.SRHover.Yoverride = 0;
TGN.SRHover.Doverride = 0;
TGN.SRHover.isPreviewModeEnabled = false;
TGN.SRHover.lastTimeout = -1;

function arrayIndexOf(array, selector) {
    for (var i = 0; i < array.length; i++)
        if (selector(array[i]))
            return i;
    return -1;
}
function wildcardCookieDomain(name, domain) {
    if (name == "")
        throw "Zero-length name not allowed.";

    if (domain == "")
        throw "Zero-length domain not allowed.";

    var domainParts = domain.split('.');
    var startingIndex = arrayIndexOf(domainParts, function (element) {
        var namePattern = new RegExp("^" + name + ".*$");
        return namePattern.test(element);
    });

    if (startingIndex == -1)
        throw "Name is not in domain.";

    return '.' + domainParts.slice(startingIndex).join('.');
}

function closeIndiv(theDB, theRec) {
    TGN.SRHover.sr_lastCmd = "close";
    var delay = 750;
    if (TGN.SRHover.Doverride > 0) {
        delay = TGN.SRHover.Doverride;
        TGN.SRHover.Doverride = 0;
    }
    var funcString = "closeIndivImpl('" + theDB + "','" + theRec + "')";
    TGN.SRHover.sr_hoveredDivEvt = setTimeout(funcString, delay);
    TGN.SRHover.sr_hoveredDiv = funcString;
}

function closeIndivDelay(theDB, theRec, delay) {
    if (typeof delay == 'undefined' || delay == null) {
        delay = 1;
    }

    TGN.SRHover.sr_lastCmd = "close";
    var funcString = "closeIndivImpl('" + theDB + "','" + theRec + "')";
    TGN.SRHover.sr_hoveredDivEvt = setTimeout(funcString, delay);
    TGN.SRHover.sr_hoveredDiv = funcString;
}

function haltIndivClose() {
    clearTimeout(TGN.SRHover.sr_hoveredDivEvt);
}

function closeIndivImpl(theDB, theRec) {
    if (TGN.SRHover.sr_lastCmd == theRec) return;
    TGN.SRHover.sr_selectedRec = 0;
    TGN.SRHover.sr_hoveredDiv = null; //clear the event marker 

    var theDiv = document.getElementById("indivHoverMain");
    if (theDiv == null) theDiv = document.getElementById(srComposeName(theDB, theRec));
    var theArrow = document.getElementById("indivHoverArrow");
    if (theArrow == null) theArrow = document.getElementById("img-" + srComposeName(theDB, theRec));

    if (theDiv == null || theArrow == null) return;
    if (theDiv.className == "hoverbox hoverboxFixed" || theDiv.className == "indivHide hoverboxFixed") {
        theDiv.className = "indivHide hoverboxFixed";
    }
    else {
        theDiv.className = "indivHide";
    }
    if (TGN.SRHover.sr_popIframe != null) {
        document.body.removeChild(TGN.SRHover.sr_popIframe);
        TGN.SRHover.sr_popIframe = null;
    }
    theArrow.className = "indivHide";
}
//Deprecate?
function showNoPreview(theEvent, theDB, theRec, headerTxt) {
    showIndiv2(theEvent, theDB, theRec, headerTxt, false, '', '', '', true);
}
//Deprecate?
function showIndiv(theEvent, theDB, theRec, headerTxt, iid, rc, xtraQSParam) {
    showIndiv2(theEvent, theDB, theRec, headerTxt, false, iid, rc, xtraQSParam, false);
}

function showIndivShortcutKeys (e, calloutLength, $calloutKeySelectLink, $calloutLink) {
    // 80 = 'p'

    var isViewHoverKeyboardShortcutPressed = e.keyCode === 80;
    var isHoverOpen = calloutLength === 1;
    var isRowSelected = $calloutKeySelectLink.length === 1;
    var isLoginBoxOpen = $('.loginBox').hasClass('on');
    var isNextOrPreviousKeyCode = TGN.SRHover.isNextOrPreviousRowKeyCode(e.keyCode);

    // when the preview is toggled off, close any open callouts (i.e. close the open hover)
    if (isViewHoverKeyboardShortcutPressed && TGN.SRHover.isPreviewModeEnabled) {
        window.ui && ui.callout ? ui.callout.close() : $.callout.close();
    }

    if (isViewHoverKeyboardShortcutPressed && isRowSelected) {
        TGN.SRHover.isPreviewModeEnabled = !TGN.SRHover.isPreviewModeEnabled;
    } else if (!isViewHoverKeyboardShortcutPressed && !isNextOrPreviousKeyCode) { // Make sure that selecting different rows with the hover on still works.
        TGN.SRHover.isPreviewModeEnabled = false;
    }

    var showHover = TGN.SRHover.isPreviewModeEnabled && !isHoverOpen && isRowSelected && isViewHoverKeyboardShortcutPressed && !isLoginBoxOpen;

    if (!showHover) {
        var isViewNextOrPreviousRowKeyboardShortcutPressed = isNextOrPreviousKeyCode;
        showHover = isViewNextOrPreviousRowKeyboardShortcutPressed && TGN.SRHover.isPreviewModeEnabled && isRowSelected && !isLoginBoxOpen;
    }
    return showHover;
}

TGN.SRHover.isNextOrPreviousRowKeyCode = function(keyCode) {
    // 190 = '>'   (technically its a '.' but if it were shifted then it would be '>')
    // 188 = '<'   (technically its a ',' but if it were shifted then it would be '<')
    //  74 = 'J'
    //  75 = 'K'
    return keyCode === 190 || keyCode === 188 || keyCode == 74 || keyCode == 75;
};

//Keypress preview record hover && mobile accessability
//Global event to fix event hijacking.
var theCalloutElementOnKeyPress;
$(function () {
    $("body").on("keyup", function (e) {
        var calloutLength = $("#callout .recordHoverContent").length,
            $calloutKeySelectLink = $("#results-main").find("tr.record.keySelect .srchFoundDB a"),
            $calloutLink = $("#results-main").find("tr.record .srchFoundDB a");
        if (showIndivShortcutKeys(e, calloutLength, $calloutKeySelectLink, $calloutLink)) {
            theCalloutElementOnKeyPress = $calloutKeySelectLink;
            $calloutKeySelectLink.mouseover();
        }
    });
});

function getHoverLink(theEvent) {
    if (theEvent && theEvent.target && theEvent.target.nodeName === "A") {
        return theEvent.target;
    }

    //Event will usually be null if using the shortcut keys.
    var hoverLink = null, curTarget = (theEvent.currentTarget) ? theEvent.currentTarget : theEvent.srcElement;
    if (curTarget) {
        hoverLink = curTarget.getElementsByTagName("a").length !== 0
            ? curTarget.getElementsByTagName("a")[0]
            : curTarget;
    }
    return hoverLink;
};

function showIndiv2(theEvent, theDB, theRec, headerTxt, subDb, iid, rc, xtraQSParam, noHover) {
    var hoverLink;
    //Event type check for IE8. Events were being run in events and this causes problems.
    if (theEvent.type == "keyup") {
        hoverLink = theCalloutElementOnKeyPress;
    } else {
        hoverLink = getHoverLink(theEvent);
    }
    //Set variables for use in new event handler.
    TGN.HoverCard.Event = theEvent;
    TGN.HoverCard.TheDB = theDB;
    TGN.HoverCard.TheRec = theRec;
    TGN.HoverCard.HeaderTxt = headerTxt;
    TGN.HoverCard.SubDB = subDb;
    TGN.HoverCard.Iid = iid;
    TGN.HoverCard.Rc = rc;
    TGN.HoverCard.xtraQSParam = xtraQSParam;
    TGN.HoverCard.NoHover = noHover;

    clearTimeout(TGN.SRHover.lastTimeout);

    var cacheKey = TGN.HoverCard.TheRec + '_' + TGN.HoverCard.TheDB;
    if (!registeredHovers[cacheKey]) {
        //Set time out to help decrease the amount of traffic that would be caused by accidental hovers.
        var timeout = setTimeout(function () {
            p_AIndiv(hoverLink);
        }, 300);
        TGN.SRHover.lastTimeout = timeout;
        $(hoverLink).on("mouseleave.recordHover", function () {
            clearTimeout(timeout);
        });
    } else if (typeof(registeredHovers[cacheKey]) === 'object') {
        registeredHovers[cacheKey].open();
    }
}

function showIndivAttached(theEvent, theDB, theRec, headerTxt, iid, url) {

    TGN.SRHover.sr_lastCmd = theRec;
    TGN.SRHover.sr_selectedRec = theRec;

    p_AIndivAttached(theEvent, theDB, "" + theRec, headerTxt, iid, url);
}

var registeredHovers = {};

function p_AIndiv(el) {
    var queryIdStr = typeof(queryId) !== 'undefined' ? queryId : '';
    var ajUrl = TGN.SResults.SearchUrlPrefix + "/Mercury/Pages/Ajax/IndivRecHoverRecordService.aspx?IndivAjax=1" +
        GetURLRecidAndPid(TGN.HoverCard.TheRec) + "&db=" + TGN.HoverCard.TheDB + "&output=html&Use302Redirect=t&page_name=" +
        utag_data.page_name + "&queryId=" + encodeURIComponent(queryIdStr);
    var elOnClick = "";
    var elParent = $(el).parents(".srchFoundDB:last");
    if (TGN.HoverCard.SubDB) ajUrl = ajUrl + "&noSub=1";
    if (TGN.HoverCard.NoHover) ajUrl = ajUrl + "&NonHover=1";

    if (TGN.HoverCard.Iid !== "" || TGN.HoverCard.Iid != null && TGN.HoverCard.Rc != "" || TGN.HoverCard.Rc != null) {
        ajUrl = ajUrl + "&iid=" + TGN.HoverCard.Iid + "&rc=" + TGN.HoverCard.Rc; //+ scrubedQS();
    }

    ajUrl = ajUrl + ((typeof isWatchBitRequest !== 'undefined' && isWatchBitRequest(location.href)) ? addWatchBitParameter(true) : "");

    if (TGN.HoverCard.xtraQSParam != null) ajUrl = ajUrl + "&" + TGN.HoverCard.xtraQSParam;

    //Set to cache hover
    var cacheKey = TGN.HoverCard.TheRec + '_' + TGN.HoverCard.TheDB;
    registeredHovers[cacheKey] = true;

    //Make request (should only happen once per record)
    $.ajax({
        type: "get",
        url: ajUrl,
        cache: false,
        xhrFields: { withCredentials: true },
        success: function (data) {
            //Scrape response data first for Bit Message
            if (typeof isWatchBitRequest !== 'undefined' && isWatchBitRequest(location.href)) {
                data.response.htmlData = processWatchBitData(data.response.htmlData);
            }
            $(el).data("isCalloutLoaded", true);
            //I am not proud of this hack, but IE8 was having problems with applying callouts to child elements.
            if (!$(el).hasClass("calloutTrigger")) {
                var onCalloutOpen = function ($trigger) {
                    // 200 is set in relationship to the preview pane on a response that is longer than the window height.
                    var setHeight = $(window).height() - 200,
                        onclickValue,
                        urlMatch;
                    $(".iivLink").each(function () {
                        $(this).attr('href', $(this).attr('href') + "&backurl=" + encodeURIComponent(window.location.href));
                    });
                    // Used to compare the record hover for Attached Records vs others that get their destination url from the Gecko control.
                    if (TGN.HoverCard.DestUrl !== "" && typeof TGN.HoverCard.DestUrl !== "undefined") {
                        onclickValue = TGN.HoverCard.DestUrl;
                    } else if ($(el).parents(".record").find(".indivHide:last").length > 0) {
                        onclickValue = $(el).parents(".record").find(".indivHide:last").attr("onclick");

                        //The onclick value here is javascript coming from Gecko that redirects to a new page, and the URL was dependent on a custom value put in an ImageLink label
                        //when the ImageLink isn't there, there isn't any URL to redirect to. Fixing this here for now is easier than fixing it in Gecko, but for anyone who wants to
                        //do that, the wonky behavior is in the GetImageUrl method in DBDisplayBase.cs in the Gecko solution (look for formatfunction=20)
                        if (onclickValue == "document.location.href=''")
                            onclickValue = "document.location.href='" + $(el).attr("href") + "'";
                    } else {
                        onclickValue = $(el).parents("tr").attr("jsopen");
                    }
                    //Move to CSS overflow and display
                    $(".hoverDataWrapper", data.responseXML).css({ "overflow-y": "auto", "max-height": setHeight, "display": "block" });
                    if (onclickValue !== "") {
                        urlMatch = onclickValue.match(/(?:^\/.*)|(?:^https?:\/\/.*)|(?:https?:\/\/[^']*)|(?:\/[^']*)|(?:javascript:[^']*)/i);
                        $(".recordHover, .recordHover .recordHoverBtn a").on("click", function (e) {
                            if (urlMatch !== null && urlMatch.length > 0) {
                                var $event = $.Event(e);
                                var srcElem = $event.target || e.srcElement || e.target;
                                if ($(srcElem).attr('data-tocid')) {
                                    return;
                                }

                                //Support for unique scenarios where javascript needs to be executed.
                                var destMatch = urlMatch[0];
                                var jsBeginning = "javascript:";
                                var indexOfJavascriptMatch = destMatch.indexOf(jsBeginning);
                                if (indexOfJavascriptMatch === 0) {
                                    destMatch = destMatch.substr(jsBeginning.length);
                                    eval(destMatch);
                                } else {
                                    window.location.href = destMatch.indexOf("/") === 0 ? TGN.SResults.SearchUrlPrefix + destMatch : destMatch;
                                }
                            }
                        });
                        if ($(".recordHover .recordHoverBtn .recordTitle").length > 0 && $(".imgPreview").length > 0) {
                            $(".recordHover .recordHoverBtn .recordTitle").text($(el).text()).attr("href",
                                TGN.SResults.SearchUrlPrefix && urlMatch && urlMatch.length > 0 && urlMatch[0].indexOf("/") === 0
                                    ? TGN.SResults.SearchUrlPrefix + urlMatch
                                    : urlMatch);
                        }
                        $("#calloutContent").on("mouseover", function () {
                            var $this = $(this);
                            if ($this.hasClass("recordHoverContent")) {
                                $this.css("background-color", "#f3f0e9");
                                $("#calloutPointerShadow").css("background-color", "#f3f0e9");
                            }
                        })
                        .on("mouseout", function () {
                            var $this = $(this);
                            if ($this.hasClass("recordHoverContent")) {
                                $this.css("background-color", "#fff");
                                $("#calloutPointerShadow").css("background-color", "#fff");
                            }
                        });
                    }
                };

                if (window.ui && ui.callout) {
                    registeredHovers[cacheKey] = ui.callout(el, {
                        calloutStyle: 'light',
                        type: 'hover',
                        position: 'right',
                        classes: 'recordHoverContent',
                        content: $(data).children("response").children("htmlData").text(),
                        onOpen: onCalloutOpen,
                        open: true
                    });
                } else {
                    $(el).callout({
                        style: 'light',
                        type: 'hover',
                        position: 'right',
                        align: 'center',
                        classes: 'recordHoverContent',
                        keepContentInPlace: false,
                        content: $(data).children("response").children("htmlData").text(),
                        showDelay: 0,
                        hideDelay: 500,
                        onAfterOpen: function ($trigger) {
                            var img = document.querySelector('.callout.open img');
                            if (img && !img.complete) {
                                img.addEventListener('load',
                                    function repositionCallout() {
                                        $trigger.callout('position');
                                    });
                            }
                        },
                        onOpen: onCalloutOpen
                    });
                }
            }

            if (!window.ui || !window.ui.callout) {
                $(el).callout("open");
            }
        }
    });
};

function p_AIndivAttached(theEvent, theDB, theRec, headerTxt, iid, url) {
    var hoverLink, myTimeout;
    if (theEvent.currentTarget) {
        hoverLink = (theEvent.currentTarget.getElementsByTagName("a").length !== 0) ? theEvent.currentTarget.getElementsByTagName("a")[0] : theEvent.currentTarget
    } else {
        hoverLink = theEvent.srcElement;
    }
    //Set variables for use in new event handler.
    TGN.HoverCard.Event = theEvent;
    TGN.HoverCard.TheDB = theDB;
    TGN.HoverCard.TheRec = theRec;
    TGN.HoverCard.HeaderTxt = headerTxt;
    TGN.HoverCard.Iid = iid;
    TGN.HoverCard.DestUrl = url;

    //Set time out to help decrease the amount of traffic that would be caused by accidental hovers.
    var cacheKey = TGN.HoverCard.TheRec + '_' + TGN.HoverCard.TheDB;
    if (!registeredHovers[cacheKey]) {
        myTimeout = setTimeout(function () {
            p_AIndiv(hoverLink);
            $(hoverLink).off("mouseleave.result");
        }, 300);
        $(hoverLink).on("mouseleave.result", function () {
            clearTimeout(myTimeout);
        });
    } else if (typeof (registeredHovers[cacheKey]) === 'object') {
        registeredHovers[cacheKey].open();
    }
};
function GetURLRecidAndPid(theRec) {
    if (theRec.toString().indexOf("__") === -1)
        return "&h=" + theRec;
    else
        return "&recid=" + theRec.toString().replace("__", "&h=");
}

function wrapHoverClick(theDiv, theWrapper) {
    if (theWrapper != null) {
        theWrapper.style.width = theDiv.offsetWidth + "px";
        theWrapper.style.height = theDiv.offsetHeight + "px";
        theWrapper.onclick = theDiv.onclick;
    }
}

function scrubedQS() {
    var query = location.search.substring(1);
    var kvPairs = query.split("&");
    var returnQ = "";

    for (var i = 0; i < kvPairs.length; i++) {
        var pos = kvPairs[i].indexOf('=');
        if (pos >= 0) {
            var key = kvPairs[i].substring(0, pos);
            if (key != "iid" && key != "rc" && key != "db" && key != "recid" && key != "output") {
                returnQ += "&" + kvPairs[i];
            }
        }
    }
    return returnQ;
}

function AddIframe(theDiv) {

    if (TGN.SRHover.sr_popIframe != null) {
        document.body.removeChild(TGN.SRHover.sr_popIframe);
        TGN.SRHover.sr_popIframe = null;
    }
    var iFrame = document.createElement("IFRAME");
    iFrame.setAttribute("src", "");
    iFrame.style.position = "absolute";
    iFrame.style.left = theDiv.offsetLeft + "px";
    iFrame.style.top = theDiv.offsetTop + "px";
    iFrame.style.width = theDiv.offsetWidth + 'px';
    iFrame.style.height = theDiv.offsetHeight + 'px';
    iFrame.style.border = "none";
    iFrame.style.backgroundColor = "transparent";
    iFrame.style.filter = "alpha(opacity=0)";
    iFrame.setAttribute("frameborder", "0");
    iFrame.setAttribute("allowtransparency", "true");
    document.body.appendChild(iFrame);
    TGN.SRHover.sr_popIframe = iFrame;
}

function getTargetArrow(cacheArrow) {
    var gDiv = document.getElementById("indivHoverArrow");
    if (gDiv != null) {
        return gDiv;
    }
    return cacheArrow;
}

function compforIEPOS(theDiv, theHeader, theFooter) {
    var offsetW = theDiv.offsetWidth;
    theHeader.style.width = theDiv.offsetWidth - 21; //21 pixels accounts for the left and right image widths	
    theDiv.style.width = offsetW; //need to reset to original width, it grows slightly when the header is filled.
}
function setDivYCoord(theDiv, yCord, fFactor) {
    var offsetP = ($(window).height() - yCord + fFactor);
    var divH = theDiv.offsetHeight;
    var yScroll = $(document).scrollTop();

    if (offsetP > (divH) || $(window).height() < divH) {
        adNum = yCord + yScroll - fFactor;
        theDiv.style.top = "" + adNum + "px";
    }
    else {
        adNum = yCord + yScroll - (divH - offsetP + fFactor);
        if (offsetP <= 90) {
            adNum = adNum + 50; //lets the bottom drop below the fold
        }
        if (adNum > 0) {
            theDiv.style.top = "" + adNum + "px";
        }
    }
}


function getNodeText(node) {
    if (typeof node.textContent != 'undefined') { return node.textContent; }
    else if (typeof node.innerText != 'undefined') { return node.innerText; }
    else if (typeof node.text != 'undefined') { return node.text; }
    else {
        switch (node.nodeType) {
            case 3:
            case 4:
                return node.nodeValue;
                break;
            case 1:
            case 11:
                var innerText = '';
                for (var i = 0; i < node.childNodes.length; i++) {
                    innerText += getNodeText(node.childNodes[i]); //getNodeText
                }
                return innerText;
                break;
            default:
                return '';
        }
    }
    return '';
}

function srComposeName(theDB, theRec) {
    return theDB + "-" + theRec;
}
/* Keyboard Shortcuts for search result pages  */

if (typeof TGN.SResults == 'undefined') { TGN.SResults = {}; };
TGN.SResults.SearchUrlPrefix = typeof searchUrlPrefix != 'undefined' ? searchUrlPrefix : '';
TGN.SResults.WwwUrlPrefix = typeof searchUrlPrefix != 'undefined' ? searchUrlPrefix : '';

var isModifierKeyPressed = function (event) {
    return event.ctrlKey || event.shiftKey || event.altKey || event.metaKey;
};

TGN.SResults.KeyListener = function () {
    var sEvents = new Array();
    var lock = false;
    
    this.additionalLoggingData = {};

    this.add = function (keyId, aFunc, evntName) {
        if (typeof keyId == 'number') {
            keyId = [keyId];
        }
        var newE = { keys: keyId, func: aFunc, evnt: evntName };
        sEvents.push(newE);
        $(document).on(evntName, function (e) {
            if (!isModifierKeyPressed(e) && $.inArray(e.which, keyId) > -1) {
                aFunc();
            }
        });
    };

    this.disable = function () {
        if (lock) return;
        $(document).off("keydown");
        $(document).off("keypress");
        $(document).off("keyup");
    };

    this.enable = function () {
        if (lock) return;
        $(sEvents).each(function (i, item) {
            $(document).bind(item['evnt'], function (e) {
                if (!isModifierKeyPressed(e) && $.inArray(e.which, item['keys']) > -1) {
                    item['func']();
                }
            });
        });
    };

    this.lockState = function () {
        lock = true;
    };

    this.unlockState = function () {
        lock = false;
    };

    this.addListeners = function () {
        for (i = 0; i < document.forms.length; i++) {
            for (x = 0; x < document.forms[i].elements.length; x++) {
                $(document.forms[i].elements[x]).on("focus", this.disable);
                $(document.forms[i].elements[x]).on("blur", this.enable);
            }
        }
    };

    this.addListener = function (el) {
        $(el).on("focus", this.disable);
        $(el).on("blur", this.enable);
    };

    this.addListeners();
};

TGN.SResults.Ftm = {};
TGN.SResults.Ftm.CurrRes = null;
TGN.SResults.Ftm.CurrNode = null;
TGN.SResults.Ftm.SelectRes = function (res) {
    if (res === null || res === TGN.SResults.Ftm.CurrRes) {
        return;
    }

    var curSelEl = document.getElementById(TGN.SResults.Ftm.CurrRes);

    // Deselect currently selected result
    if (curSelEl !== null) {
        curSelEl.className = curSelEl.className.replace(' keySelect', '');
    }

    // Select this result
    TGN.SResults.Ftm.CurrRes = res;

    if (!TGN.SResults.Ftm.HighNode()) {
        TGN.SResults.Ftm.CurrRes = null;
    }
}

TGN.SResults.Ftm.HighNode = function () {
    var nextResId = document.getElementById(TGN.SResults.Ftm.CurrRes);
    if (nextResId !== null) {
        TGN.SResults.Ftm.CurrNode = nextResId;
        nextResId.className += ' keySelect';
        nextResId.focus();

        // Fire off SelectRecord for FTM
        if (window.external && "SelectRecord" in window.external) {
            window.external.SelectRecord(TGN.SResults.Key.BuildMoniker(nextResId));
        }
        return true;
    }
    return false;
}

TGN.SResults.Ftm.NextRes = function () {
    var prevId = null, hilite = false;

    if (TGN.SResults.Ftm.CurrRes == null) {
        var result = $('#results-main .record').first();
        TGN.SResults.Ftm.CurrRes = result.attr('id');
        hilite = true;
    } else {
        if (TGN.SResults.Ftm.CurrRes.indexOf('sRes-') > -1) {
            var nextPotentialId = 'sRes-' + (parseInt(TGN.SResults.Ftm.CurrRes.substring(5)) + 1);
            var nextPotential = document.getElementById(nextPotentialId);

            if (nextPotential != null && nextPotential != 'undefined') {
                prevId = TGN.SResults.Ftm.CurrRes;
                TGN.SResults.Ftm.CurrRes = nextPotentialId;
                hilite = true;
            }
        } else {
            prevId = TGN.SResults.Ftm.CurrRes;
            TGN.SResults.Ftm.CurrRes = $('#gsresults tr.record').first().attr('id');
            hilite = true;
        }
    }

    if (hilite) {
        if (TGN.SResults.Ftm.HighNode()) {
            if (prevId) {
                var prev = document.getElementById(prevId);

                if (prev) {
                    prev.className = prev.className.replace(' keySelect', '');
                }
            }
        } else {
            TGN.SResults.Ftm.CurrRes = null;
        }
    }
};

TGN.SResults.Ftm.LastRes = function () {
    var prevId = null;
    if (TGN.SResults.Ftm.CurrRes && TGN.SResults.Ftm.CurrRes !== null) {
        prev = document.getElementById(TGN.SResults.Ftm.CurrRes);
        firstResult = $('#results-main .record').first();
        if (TGN.SResults.Ftm.CurrRes.indexOf('sRes-') > -1) {
            var prevResultNumber = parseInt(TGN.SResults.Ftm.CurrRes.substring(5)) - 1;
            var firstResultId = firstResult.attr('id');
            var prevPotentialId = prevResultNumber >= 0 || firstResultId.indexOf('result-') === -1
                                            ? 'sRes-' + prevResultNumber
                                            : firstResultId;
            var prevPotential = $('#' + prevPotentialId);

            if (prevPotential.length > 0) {
                TGN.SResults.Ftm.CurrRes = prevPotential.attr('id');
                if (TGN.SResults.Ftm.HighNode()) {
                    prev.className = prev.className.replace(' keySelect', '');
                }
            }
        }
    }
    else {
        TGN.SResults.Ftm.NextRes();
    }
};

/* Search Results Page Keyboard shortcuts */
TGN.SResults.Key = {};
TGN.SResults.Key.CurrRes = -1;
TGN.SResults.Key.CurrNode = null;
TGN.SResults.Key.CloseP = null;
TGN.SResults.Key.SelectRes = function (res) {
    // In case this comes in as a string...
    res = parseInt(res);

    // Nothing to do if current result already selected...
    if (res == TGN.SResults.Key.CurrRes || TGN.SResults.Key.CurrRes < 0) {
        return;
    }

    if (TGN.SResults.Key.CloseP != null) {
        try {
            if (typeof TGN.SResults.Key.CloseP == 'function') {
                TGN.SResults.Key.CloseP.call();
            }
            else eval(TGN.SResults.Key.CloseP);
            TGN.SResults.Key.CloseP = null;
        }
        catch (e) { }
    }

    // Deselect currently selected result
    var curSelEl = document.getElementById('sRes-' + TGN.SResults.Key.CurrRes);

    if (curSelEl != null) {
        curSelEl.className = curSelEl.className.replace(' keySelect', '');
    }

    // Select this result
    TGN.SResults.Key.CurrRes = res;

    if (!TGN.SResults.Key.HighNode()) {
        TGN.SResults.Key.CurrRes = -1;
    }
};
TGN.SResults.Key.NextRes = function () {
    if (TGN.SResults.Key.CloseP != null) {
        try {
            if (typeof TGN.SResults.Key.CloseP == 'function') {
                TGN.SResults.Key.CloseP.call();
            }
            else eval(TGN.SResults.Key.CloseP);
            TGN.SResults.Key.CloseP = null;
        }
        catch (e) { }
    }

    var prevId = null;
    var hilite = false;

    if (TGN.SResults.Key.CurrRes < 0) {
        var div = $('#results-main');
        var rows = div.find('.record').filter('tr');
        
        if (rows.length > 0) {
            var first = $(rows[0]);
            
            TGN.SResults.Key.CurrRes = parseInt(first.attr('id').substring(5));
            hilite = true;
        }
    }
    else {
        var nextPotentialId = 'sRes-' + (TGN.SResults.Key.CurrRes + 1);
        var nextPotential = $('#' + nextPotentialId)[0];

        if (nextPotential != null && nextPotential != 'undefined') {
            prevId = 'sRes-' + TGN.SResults.Key.CurrRes;
            TGN.SResults.Key.CurrRes += 1;
            hilite = true;
        }
    }

    if (hilite) {
        if (TGN.SResults.Key.HighNode()) {
            if (prevId) {
                var prev = document.getElementById(prevId);

                if (prev) {
                    prev.className = prev.className.replace(' keySelect', '');

                    var nextElPos = $('#sRes-' + (TGN.SResults.Key.CurrRes)).offset();

                    if (nextElPos != null) {
                        var nextEl = nextElPos.top;
                        var Yres = $('#' + prevId).offset().top;
                        var DocStop = $(document).scrollTop();
                        var VHi = $(window).height();
                        var diffSize = nextEl - Yres;

                        if ((nextEl + diffSize) > (VHi + DocStop)) {
                            window.scrollBy(0, diffSize + diffSize + Yres - VHi - DocStop + 10);
                        }
                    }
                }
            }
        }
        else {
            TGN.SResults.Key.CurrRes -= 1;
        }
    }
};
TGN.SResults.Key.NextorLastResUp = function () { TGN.SResults.Key.PreviewNext(); };
TGN.SResults.Key.LastRes = function () {
    if (TGN.SResults.Key.CloseP != null) {
        try {
            if (typeof TGN.SResults.Key.CloseP == 'function') {
                TGN.SResults.Key.CloseP.call();
            }
            else eval(TGN.SResults.Key.CloseP);
            TGN.SResults.Key.CloseP = null;
        }
        catch (e) { }
    }

    var prevId = null;

    if (TGN.SResults.Key.CurrRes > -1) {
        prevId = 'sRes-' + TGN.SResults.Key.CurrRes;

        var prevPotentialId = 'sRes-' + (TGN.SResults.Key.CurrRes - 1);
        var prevPotential = $('#' + prevPotentialId)[0];

        if (prevPotential != null && prevPotential != 'undefined') {
            TGN.SResults.Key.CurrRes -= 1;

            if (TGN.SResults.Key.HighNode()) {
                var prev = $('#' + prevId)[0];

                if (prev != null && prev != 'undefined') {
                    prev.className = prev.className.replace(' keySelect', '');

                    var YresPos = $('#sRes-' + TGN.SResults.Key.CurrRes).offset();

                    if (YresPos != null) {
                        var Yres = YresPos.top;
                        var DocStop = $(document).scrollTop();

                        if (Yres < DocStop) {
                            window.scrollBy(0, Yres - DocStop - 10);
                        }
                    }
                }
            }
        }
    }
    else {
        TGN.SResults.Key.NextRes();
    }
}

TGN.SResults.Key.BuildMoniker = function (element) {
    var dbid = (element.getAttribute('dbid') == undefined || element.getAttribute('dbid') == null) ? '' : element.getAttribute('dbid');
    var recordId = (element.getAttribute('rid') == undefined || element.getAttribute('rid') == null) ? '' : element.getAttribute('rid');
    var persistentId = (element.getAttribute('pid') == undefined || element.getAttribute('pid') == null) ? '' : element.getAttribute('pid');
    var groupId = (element.getAttribute('tid') == undefined || element.getAttribute('tid') == null) ? '' : element.getAttribute('tid');
    var rights = (element.getAttribute('rgt') == undefined || element.getAttribute('rgt') == null) ? '' : element.getAttribute('rgt');

    if (recordId != '' && rights != '') {
        recordId += '_A' + rights;
    }

    var result = dbid + ':' + recordId;

    if (persistentId != '') {
        result += ':' + persistentId;

        if (groupId != '') {
            result += ':' + groupId;
        }
    }

    return result;
};
TGN.SResults.Key.HighNode = function () {
    var nextResId = document.getElementById('sRes-' + TGN.SResults.Key.CurrRes);
    if (nextResId != null) {
        TGN.SResults.Key.CurrNode = nextResId;
        nextResId.className += ' keySelect';
        nextResId.focus();

        // Fire off SelectRecord if this came from FTM for the Mac
        if (typeof TGN != 'undefined' && TGN != null && typeof TGN.Ancestry != 'undefined' && TGN.Ancestry != null && typeof TGN.Ancestry.Search != 'undefined' && TGN.Ancestry.Search != null && typeof TGN.Ancestry.Search.SearchInfo != 'undefined' && TGN.Ancestry.Search.SearchInfo != null && typeof TGN.Ancestry.Search.SearchInfo.isFtm != 'undefined' && TGN.Ancestry.Search.SearchInfo.isFtm != null && TGN.Ancestry.Search.SearchInfo.isFtm) {
            if (window.external && "SelectRecord" in window.external) {
                window.external.SelectRecord(TGN.SResults.Key.BuildMoniker(nextResId));
            }
        }

        return true;
    }
    return false;
};
TGN.SResults.Key.Enter = function () {
    var tR = TGN.SResults.Key.CurrNode;

    if (tR != null) {
        var link = tR.getAttribute("jsopen");
        if (link != null && link !== '') {
            if (link.indexOf('javascript:') === 0) {
                try {
                    eval(link);
                } catch (e) {
                }
            }
        } else {
            link = $(tR).find('.link').attr('href');
        }

        link = link || $(tR).find('.srchFoundDB a').attr('href') || $(tR).find('a').attr('href');

        document.location = link;
    }
};
TGN.SResults.Key.NewSearch = function () {
};
TGN.SResults.Key.FindH = function (o) {
    if (o.className != 'srchFoundDB') return false;
    if (o.getAttribute('onmouseover') == null) return false;
    if (o.getAttribute('onmouseout') == null) return false;
    return true;
};
TGN.SResults.Key.PreviewToggle = false;
TGN.SResults.Key.ClosePreview = function () {
    if (TGN.SResults.Key.CloseP != null) {
        try {
            if (typeof TGN.SResults.Key.CloseP == 'function') {
                TGN.SResults.Key.CloseP.call();
            }
            else eval(TGN.SResults.Key.CloseP);
            TGN.SResults.Key.CloseP = null;
        }
        catch (e) { }
    }
};
TGN.SResults.Key.Preview = function () {
    if (TGN.SResults.Key.CurrRes > -1) {
        TGN.SResults.Key.PreviewToggle = !TGN.SResults.Key.PreviewToggle;
        if (!TGN.SResults.Key.PreviewToggle) TGN.SResults.Key.ClosePreview();
        TGN.SResults.Key.PreviewNext();
    }
};
TGN.SResults.Key.PreviewNext = function () {
    return;
}

//Registeres page elements that did not get registered correctly
//like those that don't have their form elements inside a form tag.
TGN.SResults.RegPageElem = function () {
    if (typeof TGN.KeyWatcher != 'undefined') {
        var idArray = ["ql_urlTxt", "ql_nameTxt", "addTodoTxt"];
        var i, len;
        for (i = 0, len = idArray.length; i < len; i++) {
            var regNode = document.getElementById(idArray[i]);
            if (regNode != null) {
                TGN.KeyWatcher.addListener(regNode);
            }
        }
    }
};

// Takes all scripts from an html element and appends tham as individual html script tags. This makes sure they get recognized in the DOM when being AJAXED in.
TGN.SResults.SetScripts = function (el) {
    var i, theText;
    var x = el.getElementsByTagName("script");
    var length = x.length;
    if (length > 0) {
        for (i = 0; i < length; i++) {
            theText = x[i].text;

            if ('undefined' != typeof theText && 'undefined' != theText && theText.length > 0) {
                var jsNode = document.createElement("script");
                jsNode.setAttribute("language", "text/javascript");
                jsNode.setAttribute("type", "text/javascript");
                jsNode.text = theText;
                el.appendChild(jsNode);
            }
        }
    }
};
// This is part of the Browse feature that exists primarily.
TGN.SResults.Browse = {
    dbid: 0,
    showBrowseDb: function (_dbid) {
        var path = getQueryStringParameter("path"), requestUrl;

        /*Toggle the link arrow*/
        $("#categories .subNavFacetList .browse").addClass("alt"); //Old Facet Design
        $("#categoryFacetSection .subNavFacetList .browse").addClass("alt"); //New Facet Design

        function getBrowsePathFromCookie(cookieName) {
            return getDictionaryCookie(cookieName, "PATHDB") == TGN.SResults.dbid
            ? getDictionaryCookie(cookieName, "PATH")
            : "";
        }

        function getBrowsePath() {
            var pathFromQueryString = getQueryStringParameter("path");

            if (!pathFromQueryString) {
                var pathFromCookie = getBrowsePathFromCookie("CBROWSES");

                if (!pathFromCookie)
                    return getBrowsePathFromCookie("BROWSES");

                return pathFromCookie;
            }

            return pathFromQueryString;
        }
        path = getBrowsePath();

        //Last Attempt to get Browse Path;
        if (null == path || path.length == 0) {
            var cookieDb = getDictionaryCookie("BROWSES", 'PATHDB');
            if (cookieDb == TGN.SResults.Browse.dbid) {
                path = getDictionaryCookie("BROWSES", 'PATH');
            }
        }

        $.ajax({
            type: 'GET',
            url: TGN.SResults.SearchUrlPrefix + '/Browse/Controls/List/BrowseHandler.ashx?root=1&dbid=' + dbid + '&path=' + path + '&pageName=' + encodeURIComponent(utag_data.page_name),
            xhrFields: { withCredentials: true },
            success: function (data) {
                if ($("#browseImagesPop").length === 0) {
                    $("#categories > .conSecond").append("<div id=\"browseImagesPop\" class=\"form\"></div>");
                    $("#categoryFacetSection > .conSecond").append("<div id=\"browseImagesPop\" class=\"form\"></div>");

                } else {
                    if ($("#browseImagesPop").css("display") === "none") {
                        $("#browseImagesPop").show();
                    } else {
                        $("#browseImagesPop").hide();
                        $("#categories .subNavFacetList .browse").removeClass("alt");
                        $("#categoryFacetSection .subNavFacetList .browse").removeClass("alt");
                    }
                }
                if ($("#browseImagesPop").html() === "") {
                    $("#browseImagesPop").html(data);
                }
            }
        });
    },
    hideBrowseDb: function () {
        TGN.KeyWatcher.enable();
    },
    currRequest: 0,
    RequestLevel: function (dbid, path, levelid, totalPathItems, callback) {
        setDictionaryCookieEx("CBROWSES", "PATHDB", dbid, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
        setDictionaryCookieEx("CBROWSES", "PATH", path, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
        requestUrl = TGN.SResults.SearchUrlPrefix + '/Browse/Controls/List/BrowseHandler.ashx?skipall=1&root=1&dbid=' + dbid + '&path=' + path + '&pageName=' + encodeURIComponent(utag_data.page_name);
        TGN.SResults.Browse.currRequest += 1;
        $.ajax({
            type: 'GET',
            url: requestUrl,
            xhrFields: { withCredentials: true },
            success: function (data) {
                $('#browseImagesPop').html(data);
                if (callback) callback();
            },
            error: function () {
                window.location.href = '/search/collections/' + TGN.SResults.Browse.dbid + '/';
            }
        });
    },
    LoadNextLevel: function (dbid, curPathItems, totalPathItems, path) {
        var el;
        for (i = curPathItems + 1; i < totalPathItems - 1; i++) {
            el = $('#browselevel' + (i - 1));
            el.html('');
        }
        if (curPathItems <= (totalPathItems - 2)) {
            el = $('#browselevel' + (totalPathItems - 2));
            el.html('');
        }
        if (path.length > 0 && curPathItems < totalPathItems) {
            $("#browselevel" + curPathItems).addClass("loading loadingSmall");
            TGN.SResults.Browse.RequestLevel(dbid, path, 'browselevel' + curPathItems, totalPathItems, function() {
                $("#browselevel" + curPathItems).removeClass("loading loadingSmall");
            });
        }

        if ($("#browselevel" + (curPathItems - 1) + " select").val() == "") {
            cookiepath = $("#browselevel" + (curPathItems - 2) + " select").val()
            if (typeof cookiepath !== 'undefined') {
                setDictionaryCookieEx("CBROWSES", "PATHDB", dbid, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
                setDictionaryCookieEx("CBROWSES", "PATH", cookiepath, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
            }
            else if (path == "") {
                setDictionaryCookieEx("CBROWSES", "PATHDB", dbid, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
                setDictionaryCookieEx("CBROWSES", "PATH", path, null, null, wildcardCookieDomain("ancestry", document.location.hostname));
            }
        }
    }
};
getQueryStringParameter = function (paramName, url) {

    var i, len, idx, queryString, params, tokens;

    url = url || top.location.href;

    idx = url.indexOf("?");
    queryString = idx >= 0 ? url.substr(idx + 1) : url;

    // Remove the hash if any
    idx = queryString.lastIndexOf("#");
    queryString = idx >= 0 ? queryString.substr(0, idx) : queryString;

    params = queryString.split("&");

    for (i = 0, len = params.length; i < len; i++) {
        tokens = params[i].split("=");
        if (tokens.length >= 2) {
            if (tokens[0] === paramName) {
                return unescape(tokens[1]);
            }
        }
    }

    return null;
};

// For Probate TOCs (and other TOCs in the future)
$(document).on('click', '.toc-moretext button, .toc-hover-moretext button', function (e) {
    var $event = $.Event(e);
    var srcElem = $event.target || e.srcElement || e.target;

    var tocId = $(srcElem).attr('data-tocid');

    var $hidden = $('#' + tocId + ' .hideVisually');
    $hidden.removeClass('hideVisually');

    $(srcElem).hide();

    $event.stopImmediatePropagation();
    $event.preventDefault();
});

// Content Success merge source tagger (tags all links on the page to record pages to use successSource=Search for tracking successSource)
$(function () {
    if (document.location.search.indexOf("ftm=1") >= 0 || document.location.search.indexOf("FTM=1") >= 0 || window.isFTMSearchPage || TGN.SResults.SearchUrlPrefix.indexOf('https://content.') === 0) return;
    $('a[href]').each(function () {
        var element = this;
        var link = element.href;
        var href = $(element).attr('href');
        if (href.indexOf('/') === 0 && href.indexOf('//') !== 0) {
            $(element).attr('href', TGN.SResults.SearchUrlPrefix + href);
            href = $(element).attr('href');
        }
        if (/^(https?:|)\/\/[^\/]*?\/cgi-bin\/sse.dll\?/.test(link)) {
            var hashIdx = link.indexOf('#');
            var linkNoFrag = hashIdx >= 0 ? link.substring(0, hashIdx) : link;
            var linkFrag = hashIdx >= 0 ? link.substring(hashIdx) : "";
            var targetQueryParams = decodeQueryParams(linkNoFrag.substring(linkNoFrag.indexOf('?')+1));
            var currentQueryParams = decodeQueryParams(window.v1SearchParams || window.location.search.substring(1));
            if (window.v1SearchParams && currentQueryParams.redir)
                delete currentQueryParams.redir;
            currentQueryParams.successSource = 'Search';
            ancestry.pub.buildUrl()
                .toGoTo('LegacySearchHandler', null, targetQueryParams)
                .toComeBackTo('LegacySearchHandler', null, currentQueryParams, null, 'successSource')
                .build(function (err, newLink) {
                    if (!err) {
                        link = newLink + linkFrag;
                        $(element).attr('href', link);
                    }
            });
        }
        if (href.indexOf('browse/view.aspx') > 0) {
            $(element).attr('href', href + '&queryId=' + queryId);
        }
    });
});

function decodeQueryParams(queryString) {
    var match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query  = queryString;

    var urlParams = {};
    while (match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);
    return urlParams;
}try
{
	if (typeof TGN == 'undefined') { var TGN = {}; }
	if (typeof TGN.Ancestry == 'undefined') { TGN.Ancestry = {}; }
	if (typeof TGN.Ancestry.Search == 'undefined') { TGN.Ancestry.Search = {}; }
	if (typeof TGN.Ancestry.Search.Collection == 'undefined') { TGN.Ancestry.Search.Collection = {}; }
}
catch (err)
{

}

// Trim a string
TGN.Ancestry.Search.Collection.Trim = function(s)
{
	return s.replace(/^\s+|\s+$/g, '');
};

// Hide a tag
TGN.Ancestry.Search.Collection.Hide = function(tag)
{
	tag.style.display = 'none'; tag.style.visibility = 'hidden';
};

// Show a tag
TGN.Ancestry.Search.Collection.Show = function(tag)
{
	tag.style.display = ''; tag.style.visibility = 'visible';
};

// Move the bread crumbs to their proper DIV
TGN.Ancestry.Search.Collection.CheckBreadCrumbs = function()
{
    var d1 = $('.g_crumbs')[0];
    var d2 = $('#breadCrumbs');
	if ((typeof d1) !== 'undefined' && d2.length > 0)
	{
		d2.append(d1);
	}
};

// If the browse has nothing in it, hide the section.
TGN.Ancestry.Search.Collection.CheckBrowse = function()
{
	var s;
	var d1 = $('#divBrowse');
	var d2 = $('#divBrowseControl');

	if ((0 == d1.length || 0 == d2.length))
	{
		return;
	}

	s = TGN.Ancestry.Search.Collection.Trim(d2.html());

	if (s.length < 1)
	{
	    d1.css('display', 'none');
	    d1.css('visibility', 'hidden');
	}
};

// Check to see if there is a banner
TGN.Ancestry.Search.Collection.CheckBanner = function () {
    var divBanner = $('#collHead');

    if (divBanner.length > 0 && divBanner.css('display') == 'none') {
        TGN.Ancestry.Search.Collection.MakeOneBarTop();
    }
};

TGN.Ancestry.Search.Collection.NW = function(url)
{
	window.open(url);
};

// Combines the top left and top right headers sections if there is no partner info and no banner image
TGN.Ancestry.Search.Collection.MakeOneBarTop = function()
{
	// No banner was found, hide the background image DIV
    var bImg = $('#collHead');
    bImg.css('visibility', 'hidden');
    bImg.css('display', 'none');
};

TGN.Ancestry.Search.Collection.DoVideo = function(file)
{
    var id = TGN.Ancestry.Search.Collection.GetVideoID();
    var url = 'https://www.ancestrycdn.com/partner-pages/video/video.js';
    $.getScript(url, function () {
        TGN.Affiliate.CEG.Video.LaunchVideo(id, file, '780', '525', cacheDomain);
    });
};

TGN.Ancestry.Search.Collection.GetVideoID = function()
{
	var a = (((1+Math.random())*0x10000)|0).toString(16).substring(1);
	return "video_" + a;
};
